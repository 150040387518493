import React from 'react'
import './style.scss'

const year = new Date().getFullYear()
const Footer = () => (
  <div className="footer">
    <footer>
      <p id="copyright"> Copyright © Nicholas Torres {year} </p>
    </footer>
  </div>
)

export default Footer
